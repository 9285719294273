class reportTableViewComponent extends Component {

    static name() {
        return "reportTableView";
    }

    static componentName() {
        return "reportTableView";
    }


    getProps() {
        return {
            reportName:{
                type: String,
                required: true
            },
            tablename:{
                type: String,
                required: true
            },
            table: {
                type: Object,
                required: true
            },
            filtrable:{
                type: Boolean,
                default: true
            },
            columnsDropdown:{
                type: Boolean,
                default: false
            },
            paginationChuck:{
                type: Number,
                default: 15
            },
            perPage:{
                type: Number,
                default: 20
            },
            getReportColumns:function(){
                this.$store.getters.getReports
            }
        };
    }


    getComputed() {
        return {
            templateFormatColumn:function(){
                let dateColumns = {};
                for(let column of this.columns){
                    if (/date/g.test(column.toLowerCase()))
                        dateColumns[column]=(h,row)=>{
                            return this.formatDate(row[column]);
                        }
                }
                return dateColumns;
            },
            options:function () {
                return {
                    filterable: this.filterable,
                    columnsDropdown: this.columnsDropdown,
                    preserveState:false,
                    headings: this.headings,
                    childRowTogglerFirst: this.childRowTogglerFirst,
                    perPage:this.perPage,
                    templates: this.templateFormatColumn,
                    pagination: { chunk:this.paginationChuck },
                    uniqueKey:'internalId',
                    texts:{
                        count:`Mostrando {from} - {to} de {count} ${this.tr('Rows')} |{count} ${this.tr('Rows')} | Una ${this.tr('Row')}`,
                        filter:this.tr('Search')+':',
                        limit:'',
                        filterPlaceholder:this.tr('Write a value'),
                        noResults:this.tr('No data were found'),
                        page:this.tr('Page')+":", // for dropdown pagination
                        filterBy: 'Filtrado por {column}', // Placeholder for search fields when filtering by column
                        loading:this.tr('Loading')+'...', // First request to server
                        defaultOption:'Select {column}', // default option for list filters,
                    },
                    rowClassCallback: function(row){
                        //console.log('.rowClassCallback',row.is_footer);
                        if(row.is_footer){
                            //console.log('row ok ');
                            return 'footer-row';
                        }
                        return false;
                    }
                }
            },
            childRowTogglerFirst:function(){
                if(this.table.detail_columns.length>0)
                    return true
                return false
            },
            childRow:function(){
                if(this.table.detail_columns.length>0)
                    return true;
                return false
            },
            headings:function() {
                return this.table.column_titles_dict;
            },
            columns:function () {
                let columns = this.table.columns.filter( (x) =>{
                    if(this.table.columns_dict[x] && this.table.columns_dict[x])
                        return this.table.columns_dict[x].visible===true;
                    return false
                });
                return columns;
            },
            subheadings:function() {
                let subhead= {}
                Object.values(this.table.detail_columns_dict).map((x)=>{
                    subhead[x.col]=this.tr(x.label);
                });
                return subhead;

            },
            reportColumns:function(){
                let reportSpec =this.$store.state.setting.ReportsRow.filter( x=>x.fields.ReportName==this.reportName && x.fields.isKPI!=true )
                if( reportSpec.length>0 ) {
                    return reportSpec[0].fields.showColumns;
                }
                return [];
            },
            subcolumns:function () {
                let columns = this.table.detail_columns.filter(x=>this.reportColumns.indexOf(x)!=-1);
                console.log(this.reportName,this.reportColumns,columns,this.table.detail_columns);
                return columns;
            },
            rows:function () {
                let rows = this.table.rows;
                if(this.table.footer.length>0){
                    if(rows.length>=this.perPage){
                        let iter = 1;
                        let max_iter = parseInt(rows.length/this.perPage);
                        if(rows.length%this.perPage>0)
                            max_iter +=1;
                        if(this.table.footer.length%max_iter>0)
                            max_iter +=1
                        max_iter = max_iter + parseInt(this.table.footer.length*max_iter/this.perPage);
                        let insert_row=false;
                        while(iter<=max_iter+1){
                            let row_counter =0;
                            for (let footer_row of this.table.footer) {
                                let temp_footer = Object.assign({is_footer:true},footer_row);
                                let insert_index=parseInt(iter*(this.perPage)-this.table.footer.length+row_counter);
                                //console.log('Iterator',iter,max_iter,insert_index,rows.length);
                                if(insert_index<rows.length)
                                    rows.splice(insert_index,0,temp_footer );
                                else{
                                    insert_row=true;
                                    rows.push(temp_footer );
                                }
                                row_counter++;
                            }
                            if(insert_row)
                                break;
                            iter++;
                        }
                    }
                    else{
                        for (let footer_row of this.table.footer){
                            let temp_footer = Object.assign({is_footer:true},footer_row);
                            rows.push(temp_footer);
                        }
                    }
                }
                return rows;
            }
        };
    }

    getMethods() {
        return {
            formatDate:this.formatDate

        };
    }

    formatDate(date) {
        let mydate = moment(date)
        if (mydate._isValid)
            return mydate.format('DD/MM/YYYY')
        return date
    }

    getTemplate() {
        return `<div class="row col-12">
                    <template v-if="childRow">
                        <v-client-table :columns="columns" :data="rows" :options="options" :ref="tablename" >
                            <template slot="child_row"  slot-scope="props" v-bind:subcolumns="subcolumns" v-bind:subheadings="subheadings">
                                <reportTableViewSubRow :row="props.row" :columns="subcolumns" :headings="subheadings" :reportName="reportName"/>
                            </template>
                        </v-client-table>
                    </template>
                    <template v-else>
                        <v-client-table :columns="columns" :data="rows" :options="options" :ref="tablename"></v-client-table>
                    </template>
              </div>`;
    }
}

reportTableViewComponent.registerComponent();